export function setupLanguageSelector(selector = '.language-selector__dropdown') {
    const languageSelectors = document.querySelectorAll(selector);

    if (languageSelectors) {

        languageSelectors.forEach(languageSelector => {

            languageSelector.addEventListener('change', function() {

                if (this.value) {
                    window.location.href = this.value;
                }
            });
        });
    }
}
