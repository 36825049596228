const tabSelector = '[data-action~="tab"]';

function openTab(e) {
    const tab = e.target ? e.target : e;

    if (!(e.ctrlKey || e.metaKey || e.shiftKey) && e.target) {
        e.preventDefault();
    }

    const tabContent = document.getElementById(tab.getAttribute('aria-controls').replace('#', ''));

    if (tabContent) {
        const activeTab = tab.parentNode.parentNode.querySelector('[aria-selected="true"]');

        if (activeTab) {
            activeTab.setAttribute('aria-selected', false);
            activeTab.setAttribute('tabindex', -1);

            const activeTabContent = document.getElementById(activeTab.getAttribute('aria-controls').replace('#', ''));
            if (activeTabContent) {
                activeTabContent.setAttribute('aria-hidden', true);
            }
        }

        tab.removeAttribute('tabindex');
        tab.setAttribute('aria-selected', true);
        tab.focus();
        tabContent.setAttribute('aria-hidden', false);
    }
}

function openTabByHashId() {
    if (window.location.hash.indexOf('tab') > -1) {
        const tabContent = document.getElementById(window.location.hash.replace('#', ''));
        if (tabContent) {
            const tab = document.getElementById(tabContent.getAttribute('aria-labelledby'));
            openTab(tab);
        }
    }
}

window.addEventListener('keydown', e => {
    const focusedElement = document.activeElement;
    const closestElement = focusedElement.closest(tabSelector);

    if (focusedElement && closestElement) {

        // Open previous tab
        if (e.key === 'ArrowLeft') {
            const newFocusedElement = closestElement.previousElementSibling;
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }

        // Open next tab
        if (e.key === 'ArrowRight') {
            const newFocusedElement = closestElement.nextElementSibling;
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }

        // Open first tab
        if (e.key === 'Home') {
            const newFocusedElement = closestElement.parentNode.querySelector(`${tabSelector}:first-child`);
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                e.preventDefault();
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }

        // Open last tab
        if (e.key === 'End') {
            const newFocusedElement = closestElement.parentNode.querySelector(`${tabSelector}:last-child`);
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                e.preventDefault();
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }
    }
});

export function setupTab() {
    const tabs = document.querySelectorAll(tabSelector);

    if (tabs) {
        tabs.forEach(tab => {
            const tabTrigger = tab.querySelector('[aria-controls]');
            if (tabTrigger) {
                tabTrigger.addEventListener('click', openTab);
            }
        });
    }

    openTabByHashId();

    window.addEventListener('hashchange', openTabByHashId, false);
}
