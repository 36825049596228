import axios from 'axios';

const culture = document.documentElement.lang;

const apiService = {
    ac: {
        getForm(formId) {
            return axios.get('/Umbraco/Api/ActiveCampaignApi/GetForm', { params: { culture, formId } }).then(response => response);
        },
        addContact(contact, actiondata) {
            return axios.post('/Umbraco/Api/ActiveCampaignApi/AddContact', { culture, contact, actiondata }).then(response => response);
        }
    }
};

export default apiService;
