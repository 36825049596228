import { stickyNavigation } from '../components/sticky-navigation';
import { enableScrollLock, disableScrollLock } from '../utils/scroll';
import { breakpoints, currentWindowWidth } from '../utils/window-resize';

export let navigation;

const navSelector = '.navigation',
    navAccordionSelector = '[data-action="nav-accordion"]',
    openClass = 'navigation--open',
    stickyClass = 'navigation--sticky',
    goingUpClass = 'navigation--going-up';

function clickOutside(e) {
    if (!navigation.contains(e.target)) {
        const openedAccordions = navigation.querySelectorAll('[aria-expanded="true"]');
        if (openedAccordions.length) {
            openedAccordions.forEach(openedAccordionTrigger => {
                const oppenedAccordionContainer = openedAccordionTrigger.closest(navAccordionSelector);
                const openedAccordionContent = oppenedAccordionContainer.querySelector('[aria-labelledby]');
                closeNavAccordion(openedAccordionTrigger, openedAccordionContent);
            });
        }
    }
}

function openNavAccordion(trigger, content) {
    content.style.height = `${content.scrollHeight}px`;

    content.addEventListener('transitionend', function(e) {
        if (e.propertyName === 'height') {
            content.style.height = null;
        }
    });

    content.setAttribute('aria-hidden', false);
    trigger.setAttribute('aria-expanded', true);
}

function closeNavAccordion(trigger, content) {
    content.style.height = `${content.scrollHeight}px`;

    setTimeout(() => {
        content.style.height = '0';
    }, 0);

    content.setAttribute('aria-hidden', true);
    trigger.setAttribute('aria-expanded', false);
}

function toggleNavAccordion(e) {
    if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
        e.preventDefault();
    }

    const trigger = e.currentTarget;
    const navAccordion = trigger.closest(navAccordionSelector);
    const navAccordionOpenedSiblings = navAccordion.parentNode.querySelectorAll('[aria-expanded="true"]');

    if (navAccordion) {
        const opened = trigger.getAttribute('aria-expanded') == 'true';
        const content = navAccordion.querySelector('[aria-labelledby]');

        if (opened) {
            closeNavAccordion(trigger, content);
        } else {
            if (navAccordionOpenedSiblings.length) {
                navAccordionOpenedSiblings.forEach(openedAccordionTrigger => {
                    const oppenedAccordionContainer = openedAccordionTrigger.closest(navAccordionSelector);
                    const openedAccordionContent = oppenedAccordionContainer.querySelector('[aria-labelledby]');

                    if (trigger !== openedAccordionTrigger) {
                        closeNavAccordion(openedAccordionTrigger, openedAccordionContent);
                    }
                });
            }
            openNavAccordion(trigger, content);
        }
    }
}

export function toggleMenuOpen() {
    if (navigation.classList.contains(openClass)) {
        disableScrollLock();
        navigation.classList.remove(openClass);

        if (window.pageYOffset > 50) {
            setTimeout(() => navigation.classList.add(goingUpClass), 40);
        }

    } else {
        enableScrollLock();
        navigation.classList.add(openClass);
    }
}

export function setupNavAccordions() {

    const navAccordions = document.querySelectorAll(navAccordionSelector);
    const isNavAccordionEnabled = currentWindowWidth < (breakpoints['lg'] - .02);

    if (navAccordions) {

        navAccordions.forEach(navAccordion => {
            const navAccordionTrigger = navAccordion.querySelector('[aria-controls]');

            if (navAccordionTrigger) {
                if (isNavAccordionEnabled) {
                    navAccordionTrigger.addEventListener('click', toggleNavAccordion);
                } else {
                    navAccordionTrigger.removeEventListener('click', toggleNavAccordion);
                }
            }
        });

        if (isNavAccordionEnabled) {
            document.body.addEventListener('click', clickOutside);
        } else {
            document.body.removeEventListener('click', clickOutside);
        }
    }
}

export function setupNavigation() {
    navigation = document.body.querySelector(navSelector);

    if (navigation) {
        const navigationBtn = navigation.querySelector('.navigation__btn');

        if (navigationBtn) {
            navigationBtn.addEventListener('click', toggleMenuOpen);
        }

        setupNavAccordions();
        stickyNavigation(navigation, stickyClass, 30, goingUpClass);
    }
}
