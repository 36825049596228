<template>
    <form v-if="!hasBeenSubmitted" ref="form" class="form" :class="{ 'form--submitting': isSubmitting }" autocomplete="off" @submit.prevent="submitForm">
        <template v-if="isInitializing">
            <div class="form__row">
                <div class="form__field-wrap">
                    <div class="form__placeholder-input"></div>
                </div>
            </div>
            <div class="form__row">
                <div class="form__field-wrap">
                    <div class="form__placeholder-input"></div>
                </div>
            </div>
            <div class="form__row form__row--policy">
                <div class="form__field-wrap">
                    <div class="form__placeholder-checkbox"></div>
                </div>
            </div>
            <div class="form__row form__row--submit">
                <div class="form__field-wrap">
                    <div class="form__placeholder-button"></div>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-for="(field, index) in filteredCFields" :key="index">

                <!-- Standard Field (type: "Header") -->
                <template v-if="field.type === 'header'">
                    <span class="form__headline">{{ field.header }}</span>
                </template>

                <!-- Standard Field (type: "Text") -->
                <template v-else-if="field.type === 'fullname' || field.type === 'firstname' || field.type === 'lastname' || field.type === 'customer_account'">
                    <div class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.type)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <input :id="getUniqueId(field.type)" :name="field.type" type="text" class="form__input" :value="field.defaultValue" :placeholder="getFieldTitle(field, true)" :title="getFieldTitle(field)" :aria-required="field.required" :required="field.required" />
                    </div>
                </template>

                <!-- Standard Field (type: "Email") -->
                <template v-else-if="field.type === 'email'">
                    <div class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.type)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <input :id="getUniqueId(field.type)" ref="email" :name="field.type" type="email" class="form__input" :value="field.defaultValue" :placeholder="getFieldTitle(field, true)" :title="getFieldTitle(field)" :aria-required="field.required" :required="field.required" />
                    </div>
                </template>

                <!-- Standard Field (type: "Phone") -->
                <template v-else-if="field.type === 'phone'">
                    <div class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.type)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <input :id="getUniqueId(field.type)" :name="field.type" type="tel" class="form__input" :value="field.defaultValue" :placeholder="getFieldTitle(field, true)" :title="getFieldTitle(field)" :aria-required="field.required" :required="field.required" />
                    </div>
                </template>

                <!-- Custom Field (type: "Text Input") -->
                <template v-else-if="field.type === 'text'">
                    <div class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.id)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <input :id="getUniqueId(field.id)" :name="field.id" type="text" class="form__input" data-custom-field :value="field.defaultValue" :placeholder="getFieldTitle(field, true)" :title="getFieldTitle(field)" :aria-required="field.required" :required="field.required" />
                    </div>
                </template>

                <!-- Custom Field (type: "Text Area") -->
                <template v-else-if="field.type === 'textarea'">
                    <div class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.id)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <textarea :id="getUniqueId(field.id)" :name="field.id" class="form__textarea" data-custom-field :value="field.defaultValue" :placeholder="getFieldTitle(field, true)" :title="getFieldTitle(field)" :aria-required="field.required" :required="field.required"></textarea>
                    </div>
                </template>

                <!-- Custom Field (type: "Dropdown") -->
                <template v-else-if="field.type === 'dropdown'">
                    <div v-if="field.options" class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.id)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <div class="form__select-wrap">
                            <select :id="getUniqueId(field.id)" :name="field.id" class="form__select" data-custom-field :title="getFieldTitle(field)" :aria-required="field.required" :required="field.required">
                                <option value="" :selected="!field.options.some(o => o.isdefault)">
                                    {{ getFieldHeader(field) }}
                                </option>
                                <option v-for="(option, dropdownIndex) in field.options" :key="dropdownIndex" :value="option.value" :selected="option.isdefault">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "List Box") -->
                <template v-else-if="field.type === 'listbox'">
                    <div v-if="field.options" class="form__row">
                        <label v-if="field.header" :for="getUniqueId(field.id)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <div class="form__select-wrap form__select-wrap--multiple">
                            <select :id="getUniqueId(field.id)" :name="field.id" class="form__select" data-custom-field :title="getFieldTitle(field)" multiple :aria-required="field.required" :required="field.required">
                                <option v-for="(option, listboxIndex) in field.options" :key="listboxIndex" :value="option.value" :selected="option.isdefault">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "Radio Button") -->
                <template v-else-if="field.type === 'radio'">
                    <div class="form__row">
                        <span v-if="field.header" class="form__label">{{ getFieldHeader(field) }}</span>
                        <div class="form__checkbox-radio-list">
                            <div v-for="(option, radioIndex) in field.options" :key="radioIndex" class="form__checkbox-radio-wrap">
                                <input :id="getUniqueId(field.id, option.id)" class="form__radio" data-custom-field type="radio" :name="field.id" :value="option.value" :checked="option.isdefault" :aria-required="field.required" :required="field.required" />
                                <label :for="getUniqueId(field.id, option.id)" class="form__checkbox-radio-label">{{ option.label }}</label>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "Checkbox") -->
                <template v-else-if="field.type === 'checkbox'">
                    <div v-if="field.options" class="form__row">
                        <template v-if="field.options.length > 1">
                            <span v-if="field.header" class="form__label">{{ getFieldHeader(field) }}</span>
                            <div class="form__checkbox-radio-list">
                                <div v-for="(option, checkboxIndex) in field.options" :key="checkboxIndex" class="form__checkbox-radio-wrap">
                                    <input :id="getUniqueId(field.id, option.id)" class="form__checkbox" data-custom-field type="checkbox" :name="field.id" :value="option.value" :checked="option.isdefault" />
                                    <label :for="getUniqueId(field.id, option.id)" class="form__checkbox-radio-label">{{ option.label }}</label>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <span v-if="field.header" class="form__label">{{ getFieldHeader(field) }}</span>
                            <div class="form__checkbox-radio-wrap">
                                <input :id="getUniqueId(field.id, field.options[0].id)" class="form__checkbox" data-custom-field type="checkbox" :name="field.id" :value="field.options[0].value" :checked="field.options[0].isdefault" :aria-required="field.required" :required="field.required" />
                                <label :for="getUniqueId(field.id, field.options[0].id)" class="form__checkbox-radio-label">{{ field.options[0].label }}</label>
                            </div>
                        </template>
                    </div>
                </template>

                <!-- Custom Field (type: "Policy") -->
                <template v-else-if="field.type === 'policy'">
                    <div v-if="field.options" class="form__row">
                        <div class="form__checkbox-radio-wrap form__checkbox-radio-wrap--policy">
                            <span v-if="field.header" class="form__label">{{ getFieldHeader(field) }}</span>
                            <div class="form__checkbox-radio-wrap">
                                <input :id="getUniqueId(field.id, field.options[0].id)" class="form__checkbox" data-custom-field type="checkbox" :name="field.id" :value="field.options[0].value" :checked="field.options[0].isdefault" :aria-required="field.required" :required="field.required" />
                                <label :for="getUniqueId(field.id, field.options[0].id)" class="form__checkbox-radio-label">
                                    <span v-html="getPolicyLabel(field)"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "Hidden") -->
                <template v-else-if="field.type === 'hidden'">
                    <input :id="getUniqueId(field.id)" :name="field.id" data-custom-field type="hidden" :value="field.defaultValue" />
                </template>

                <!-- Unsupported types -->
                <template v-else>
                    <div class="form__row" style="border: 2px dotted red; font-size: 12px;">
                        <p>Field type not supported: <strong>{{ field.type }} // {{ field.header }}</strong></p>
                    </div>
                </template>

            </template>
            <div v-if="form.cfields" class="form__row">
                <div class="form__btn-wrap">
                    <button type="submit" class="form__btn btn">
                        <span class="btn__icon">
                            <svg-inline name="arrow-with-line" />
                        </span>
                        <span class="btn__text">{{ form.button }}</span>
                        <span class="btn__icon">
                            <svg-inline name="arrow-with-line" />
                        </span>
                    </button>
                </div>
            </div>
            <div class="spinner-wrap" :class="{ 'spinner-wrap--absolute': isSubmitting }" :aria-hidden="isSubmitting ? 'false' : 'true'">
                <div class="spinner"></div>
            </div>
        </template>
    </form>
    <div v-if="message" class="form__message" :class="{ 'form__message--error': hasError }">
        {{ message }}
    </div>
</template>

<script>
import apiService from '../../api';
import SvgInline from '../components/svg-inline.vue';

export default {
    name: 'AcForm',
    components: {
        SvgInline
    },
    props: {
        formId: {
            type: String,
            default: ''
        },
        formKey: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isInitializing: true,
            isSubmitting: false,
            hasBeenSubmitted: false,
            form: [],
            hasError: false,
            message: ''
        };
    },
    computed: {
        filteredCFields() {
            if (!this.isInitializing) {
                const allowedTypes = ['header', 'fullname', 'firstname', 'lastname', 'customer_account', 'email', 'phone', 'text', 'textarea', 'dropdown', 'listbox', 'radio', 'checkbox', 'policy', 'hidden'];
                let filteredObject = this.form.cfields;
                if (this.form.cfields) {
                    filteredObject = this.form.cfields.filter(cfield => (allowedTypes.includes(cfield.type)));
                }
                return filteredObject;
            }
            return null;
        }
    },
    created() {
        this.getForm();
    },
    methods: {
        getForm() {
            const vm = this;

            apiService.ac.getForm(vm.formId)
                .then(response => {
                    vm.form = response.data.form;
                })
                .catch(errors => {
                    const errorResponse = errors.response.data.errorMessage ? errors.response.data.errorMessage : `Error (status code ${errors.response.status})`;
                    vm.hasError = true;
                    vm.message = errorResponse;
                })
                .then(function () {
                    vm.isInitializing = false;
                });
        },
        submitForm() {
            const vm = this,
                contactObj = vm.mappedContactObj();

            vm.isSubmitting = true;
            vm.hasError = false;
            vm.message = '';

            apiService.ac.addContact(contactObj, vm.form.actiondata)
                .then(function () {

                    // /* Tracking */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'form_submission',
                        'formName': vm.form.name,
                        'userEmail': vm.$refs.email[0].value
                    });

                    if (vm.form.submit === 'open-url' && vm.form.submitdata.url) {
                        window.location.href = vm.form.submitdata.url;
                    } else {
                        vm.message = vm.form.thanks;
                        vm.hasBeenSubmitted = true;
                    }
                })
                .catch(errors => {
                    const errorResponse = errors.response.data.errorMessage ? errors.response.data.errorMessage : `Error (status code ${errors.response.status})`;
                    vm.hasError = true;
                    vm.message = errorResponse;
                    vm.hasBeenSubmitted = true;
                })
                .then(function () {
                    vm.isSubmitting = false;
                });
        },
        mappedContactObj() {
            const form = this.$refs.form,
                formElements = form.querySelectorAll('input, textarea, select'),
                formData = {
                    fieldValues: []
                };

            if (formElements) {
                formElements.forEach(el => {

                    if (el.hasAttribute('data-custom-field')) {

                        if (el.type && el.type === 'radio') {

                            if (el.checked) {
                                formData.fieldValues.push({ field: el.name, value: el.value });
                            }

                        } else if (el.type && el.type === 'checkbox') {

                            if (el.checked && !formData.fieldValues.some(e => e.field === el.name)) {
                                const possibleSiblings = form.querySelectorAll(`[name="${el.name}"]:checked`);
                                const selectedCheckboxes = Array.from(possibleSiblings).map(({ value }) => value);
                                const values = selectedCheckboxes.join('||');
                                formData.fieldValues.push({ field: el.name, value: `||${values}||` });
                            }

                        } else if (el.tagName === 'SELECT' && el.hasAttribute('multiple')) {

                            if (el.selectedIndex !== -1) {
                                const selectedOptions = Array.from(el.selectedOptions).map(({ value }) => value);
                                const values = selectedOptions.join('||');
                                formData.fieldValues.push({ field: el.name, value: `||${values}||` });
                            }

                        } else {

                            if (el.value) {
                                formData.fieldValues.push({ field: el.name, value: el.value });
                            }
                        }

                    } else {

                        if (!el.value) {
                            return;
                        }

                        if (el.name === 'fullname') {
                            const fullName = el.value;
                            let firstName = fullName;
                            let lastName = '';

                            if (fullName.indexOf(' ') >= 0) {
                                firstName = fullName.substr(0,fullName.indexOf(' '));
                                lastName = fullName.substr(fullName.indexOf(' ') + 1);
                            }

                            formData['firstname'] = firstName;
                            formData['lastname'] = lastName;

                        } else {
                            formData[el.name] = el.value;
                        }
                    }
                });
            }

            return formData;
        },
        getUniqueId(value1, value2 = '') {
            let uniqueId = `form[${this.formKey}]-${value1}`;
            uniqueId = value2 ? `${uniqueId}-${value2}` : uniqueId;
            return uniqueId;
        },
        getFieldHeader(field) {
            let label = field.header;
            if (field.required && label)
            {
                label = `${label.replace(/\*/g, '')}*`;
            }
            return label;
        },
        getFieldTitle(field, isPlaceholder) {
            let label = field.default_text;
            if (field.required && label)
            {
                label = label.replace(/\*/g, '');

                if (isPlaceholder && !field.header) {
                    label = `${label}*`;
                }
            }
            return label;
        },
        getPolicyLabel(field) {
            let label = field.options[0].label;
            if (field.required && label)
            {
                label = label.replace(/\*/g, '');

                if (!field.header) {
                    label = `${label}*`;
                }
            }
            return label;
        }
    }
};
</script>
