let functionReferences = [];
let readyState = document.readyState === 'interactive' || document.readyState === 'complete';
let readyEventBound = false;

function runFunctionArray(funcArray) {
    funcArray.forEach(funcRef => funcRef());
}

function emptyCallbackArrays() {
    while (functionReferences.length) {
        const tempArray = functionReferences.slice(0);
        functionReferences = [];
        runFunctionArray(tempArray);
    }
    readyState = true;
}

function bindReadyEvent() {
    if (!readyEventBound) {
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', emptyCallbackArrays);
        } else {
            emptyCallbackArrays();
        }
        readyEventBound = true;
    }
}

export function onReady(functionReference) {
    if (typeof functionReference === 'function') {
        if (readyState) {
            functionReference();
        } else {
            bindReadyEvent();
            functionReferences.push(functionReference);
        }
    }
}
