import { createApp } from 'vue';
import AcForm from '../vue/components/ac-form.vue';

export function setupVue() {

    const app = createApp();

    // We need this to make sure "white-space: pre-line" still works
    app.config.compilerOptions.whitespace = 'preserve';

    app.component('AcForm', AcForm);

    app.mount('#app');
}
