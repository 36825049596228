import { getElementScroll } from './helpers';

let ticking = false;
let scrollLockTop = 0;
const scrollLockClassName = 'scroll-lock';
const scrollFunctions = [];

function animate() {
    scrollFunctions.forEach(funcRef => funcRef());
    ticking = false;
}

function requestTick() {
    if (!ticking) {
        requestAnimationFrame(animate);
        ticking = true;
    }
}

function scrollHandler() {
    scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    requestTick();
}

export let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

export function onScroll(handler, triggerNow = false) {
    !scrollFunctions.length ? initScroll() : null;
    triggerNow ? handler() : null;
    scrollFunctions.push(handler);
}

export function initScroll() {
    window.addEventListener('scroll', scrollHandler);
}

export function scrollTo(target) {
    if (typeof target === 'string' || target instanceof String) {
        if (target.toLowerCase() == 'top') {
            window.scrollTo({
                top: 0,
                left: 0
            });
        } else if (target.toLowerCase() == 'bottom') {
            window.scrollTo({
                top: document.body.scrollHeight,
                left: 0
            });
        } else if (target.startsWith('#')) {
            const targetElm = document.getElementById(target.replace('#', ''));
            if (targetElm) {
                targetElm.scrollIntoView({
                    block: 'start',
                    inline: 'nearest'
                });
            }
        }
    } else if (target) {
        target.scrollIntoView({
            block: 'start',
            inline: 'nearest'
        });
    }
}

export let scrollLocked = false;

export function enableScrollLock() {
    if (!scrollLocked) {
        const scrollPosition = getElementScroll();
        window.scrollTo(scrollPosition.left, 0);

        const htmlTag = document.documentElement;
        htmlTag.classList.add(scrollLockClassName);
        htmlTag.style.marginTop = `${-scrollPosition.top}px`;
        htmlTag.style.position = 'fixed';
        htmlTag.style.overflow = 'hidden';
        htmlTag.style.width = '100%';
        document.body.style.overflowY = 'scroll';

        scrollLocked = true;
        scrollLockTop = scrollPosition.top;
    }
}

export function disableScrollLock() {
    if (scrollLocked) {
        const scrollPosition = getElementScroll();

        const htmlTag = document.documentElement;
        htmlTag.classList.remove(scrollLockClassName);
        htmlTag.style.marginTop = '';
        htmlTag.style.position = '';
        htmlTag.style.overflow = '';
        htmlTag.style.width = '';
        document.body.removeAttribute('style');

        window.scrollTo(scrollPosition.left, scrollLockTop);

        scrollLocked = false;
    }
}

export function toggleScrollLock() {
    if (scrollLocked) {
        disableScrollLock();
    } else {
        enableScrollLock();
    }
}

export function initAnchorScroll(selector = 'a[href^="#"]:not([href^="#/"]):not([role]') {
    const anchorLinks = document.querySelectorAll(selector);

    anchorLinks.forEach(anchorLink => {
        anchorLink.addEventListener('click', e => {
            const anchorElm = document.getElementById(anchorLink.getAttribute('href').replace('#', ''));

            if (anchorElm) {
                e.preventDefault();

                scrollTo(anchorElm);
            }
        });
    });
}
