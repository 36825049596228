const accordionSelector = '[data-action~="accordion"]';

function openAccordion(trigger, content) {
    content.style.height = `${content.scrollHeight}px`;

    content.addEventListener('transitionend', function(e) {
        if (e.propertyName === 'height') {
            content.style.height = null;
        }
    });

    content.setAttribute('aria-hidden', false);
    trigger.setAttribute('aria-expanded', true);
}

function closeAccordion(trigger, content) {
    content.style.height = `${content.scrollHeight}px`;

    setTimeout(() => {
        content.style.height = '0';
    }, 0);

    content.setAttribute('aria-hidden', true);
    trigger.setAttribute('aria-expanded', false);
}

function openAccordByHashId() {
    if (window.location.hash.indexOf('accordion') > -1) {
        const content = document.getElementById(window.location.hash.replace('#', ''));

        if (content) {
            const accordion = document.getElementById(content.getAttribute('aria-labelledby'));
            accordion.click();
        }
    }
}

function toggleAccordion(e) {
    if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
        e.preventDefault();
    }

    const trigger = e.currentTarget;
    const accordion = trigger.closest(accordionSelector);
    const accordionOpenedSiblings = accordion.parentNode.querySelectorAll('[aria-expanded="true"]');
    const closeSiblingsOnToggle = accordion.parentNode.getAttribute('data-close-siblings') == 'true';

    if (accordion) {
        const opened = trigger.getAttribute('aria-expanded') == 'true';
        const content = accordion.querySelector('[aria-labelledby]');

        if (opened) {
            closeAccordion(trigger, content);
        } else {
            if (closeSiblingsOnToggle && accordionOpenedSiblings.length) {
                accordionOpenedSiblings.forEach(openedAccordionTrigger => {
                    const oppenedAccordionContainer = openedAccordionTrigger.closest(accordionSelector);
                    const openedAccordionContent = oppenedAccordionContainer.querySelector('[aria-labelledby]');

                    if (trigger !== openedAccordionTrigger) {
                        closeAccordion(openedAccordionTrigger, openedAccordionContent);
                    }
                });
            }
            openAccordion(trigger, content);
        }
    }
}

function setupAccordion(accordionTrigger) {
    accordionTrigger.addEventListener('click', toggleAccordion);
}

window.addEventListener('keydown', e => {
    const focusedElement = document.activeElement;
    const closestElement = focusedElement.closest(accordionSelector);

    if (focusedElement && closestElement) {

        // Focus previous accordion
        if (e.key === 'ArrowUp') {
            const newFocusedElement = closestElement.previousElementSibling;
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                newFocusedElement.querySelector('[aria-controls]').focus();
            }
        }

        // Focus next accordion
        if (e.key === 'ArrowDown') {
            const newFocusedElement = closestElement.nextElementSibling;
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                newFocusedElement.querySelector('[aria-controls]').focus();
            }
        }

        // Focus first accordion
        if (e.key === 'Home') {
            const newFocusedElement = closestElement.parentNode.querySelector(`${accordionSelector}:first-child`);
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                e.preventDefault();
                newFocusedElement.querySelector('[aria-controls]').focus();
            }
        }

        // Focus last accordion
        if (e.key === 'End') {
            const newFocusedElement = closestElement.parentNode.querySelector(`${accordionSelector}:last-child`);
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                e.preventDefault();
                newFocusedElement.querySelector('[aria-controls]').focus();
            }
        }
    }
});

export function setupAccordions() {
    const accordions = document.querySelectorAll(accordionSelector);

    if (accordions.length) {

        accordions.forEach(accordion => {
            const accordionTrigger = accordion.querySelector('[aria-controls]');

            if (accordionTrigger) {
                setupAccordion(accordionTrigger);
            }
        });

        openAccordByHashId();
    }
}
